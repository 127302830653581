.user-incident-detail__block {
    width: 50%;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.user-incident-detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 30px;
    justify-content: space-between;
    border-radius: 8px;
    min-height: 55px;
    height: 55px;
    font-weight: bold;
    width: calc(100% - 60px);
}

.user-incident-detail__header span {
    margin-right: 10px;
}

.user-incident-detail__header_darkTheme {
    background-color: #383838;
    color: var(--font-color-dark);
}

.user-incident-detail__header_lightTheme {
    background-color: #0082C8;
    color: #fff;
}

.user-incident-detail__initiator-block {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px;
    width: 45%;
}

.user-incident-detail__initiator-block_darkTheme {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.user-incident-detail__initiator-block_lightTheme {
    background-color: var(--block-color-light);
}

.user-incident-detail__initiator-row {
    display: flex;
    flex-direction: row;
}

.user-incident-detail__first-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    border-radius: 8px;
    max-height: calc(50% - 55px);
}

.user-incident-detail__first-row_darkTheme {
    background-color: #2E2E2E;
    color: var(--font-color-dark);
}

.user-incident-detail__first-row_lightTheme {
    background-color: #fff;
    color: var(--font-color-light);
}

.user-incident-detail__second-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
}

.user-incident-detail {
    width: 50%;
}

#first-column {
    width: 40%;
}

#second-column {
    width: 60%;
}

.user-incident-description__block {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: pre-line;
    padding: 15px 20px;
    word-break: break-all;
}

#user-incident-detail__toggle-block {
    padding: 0px;
}

.user-incident-description__block_darkTheme {
    color: var(--font-color-dark);
}

.user-incident-description__block_lightTheme {}

.user-incident-detail__left-block {
    width: 60%;
}

.user-incident-detail__chat-block {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    gap: 5px;
}

.user-incident-detail__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 20px;
    width: calc(100% - 40px);
    position: relative;
    z-index: 999;
    border-radius: 8px;
    justify-content: center;
}

.user-incident-detail__switch p {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-incident-detail__switch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 50%;
    height: 30px;
}

.user-incident-detail__switch_darkTheme {
    background-color: #2E2E2E;
    color: var(--font-color-dark);
}

.user-incident-detail__switch_lightTheme {
    background-color: #fff;
    color: var(--font-color-light);
}

.user-incident-detail__switch-button_darkTheme {
    background-color: var(--button-color-dark);
    color: var(--font-color-dark);
}

.user-incident-detail__switch-button_lightTheme {
    background-color: var(--button-color-light);
    color: var(--font-color-light);
}

.user-incident-detail__select-row {
    display: grid;
    align-items: center;
    grid-template-columns: 130px 1fr;
    grid-template-rows: 1fr;
    padding-right: 10px;
}

.user-incident-detail__select-row p {
    width: 120px;
}

.user-incident-detail__select {
    width: calc(100% - 130px);
    height: 40px;
    border-radius: 8px;
}

.user-incident-detail__select option {
    height: 40px;
}

.user-incident-detail__select-row__p_darkTheme {
    color: var(--font-color-dark);
}

.user-incident-detail__select-row__p_lightTheme {
    color: var(--font-color-light);
}

.user-incident-detail__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 1px);
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    background-image: var(--detail-background);
}

.user-incident-detail__overlay_darkTheme {
    border-left: 1px solid #121212;
}

.user-incident-detail__overlay_lightTheme {
    border-left: 1px solid #7FC0E4;
}

.user-incident-detail__overlay-body {
    background-color: var(--list-item-color);
    width: 320px;
    height: 120px;
    color: var(--font-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 120%;
    gap: 5px;
    border-radius: 12px;
}

.user-incident-detail__overlay-body p:first-of-type {
    font-weight: bold;
    font-size: 16px;
}

.user-incident-detail__overlay-body p:last-of-type {
    font-size: 14px;
    opacity: .6;
}

#inc-title {
    overflow: visible;
    padding-bottom: 0px;
}

.user-incident-detail__toggle-block {
    display: flex;
    flex-direction: row;
    padding: 0px 15px;
    gap: 15px;
    user-select: none;
    -webkit-user-drag: none;
    color: var(--font-color-tr);
    align-items: center;
}

.user-incident-detail__toggle-block svg {
    color: var(--font-color);
}

.user-incident-detail__toggle-block svg:hover {
    cursor: pointer;
}

.user-incident-detail__toggle-item {
    font-weight: bold;
    border-bottom: 2px solid transparent;
}

.user-incident-detail__toggle-item:hover {
    cursor: pointer;
}

.user-incident-detail__toggle-item_active {
    border-bottom: 2px solid var(--phone-button-border-color);
    color: var(--font-color);
}

.user-incident-detail__toggle-item_active:hover {
    cursor: default;
}

@media screen and (max-width: 1335px) {
}

@media screen and (max-width: 767px) {
    .user-incident-detail__first-row {
        border-radius: 8px;
        width: calc(100% - 20px);
        box-shadow: var(--chat-shadow-color);
    }

    .user-incident-detail__chat-block {
        gap: 0px;
    }

    .user-incident-detail {
        width: 100%;
        height: 100%;
    }

    .user-incident-detail__switch {
        min-height: auto;
        height: auto;
        gap: 15px;
        width: calc(100% - 50px);
        padding: 15px;
        border-radius: 8px;
        box-shadow: var(--chat-shadow-color);
    }

    .user-incident-detail__switch p {
        max-width: 100%;
    }

    .user-incident-detail__block {
        width: 100%;
        border-radius: 0px;
    }

    .user-incident-detail__header {
        border-radius: 8px;
        padding: 0px 20px;
        width: calc(100% - 60px);
        min-height: 50px;
        height: 50px;
        box-shadow: var(--chat-shadow-color);
    }

    .user-incident-detail__header_darkTheme {
        background-color: #383838;
    }

    .user-incident-detail__header_lightTheme {
        background-color: var(--accent-color-pridex);
    }

    .user-incident-description__block {
        max-height: 200px;
        padding: 15px;
        width: calc(100% - 30px);
    }

    .user-chat__send-button-div {
        min-width: 40px;
        height: 40px;
    }
}