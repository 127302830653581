.chat__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}

.chat__main-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
}

.chat__main-block_darkTheme {
    background-color: var(--block-color-dark);
}

.chat__main-block_lightTheme {
    background-color: var(--secondary-color-light);
}

.chat__input-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    border-radius: 0px 0px 8px 8px;
    position: relative;
}

.chat__input-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    gap: 10px;
    border-radius: 0px 0px 8px 8px;
    padding: 5px 10px;
}

.chat__input-row_darkTheme {
    background-color: var(--block-color-dark);
}

.chat__input-row_lightTheme {
    background-color: var(--secondary-color-light);
}

.chat__pin-button {
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url(../../utils/images/clip.svg);
}

.chat__pin-button input {
    display: none;
}

.chat__pin-button:hover {
    cursor: pointer;
}

.chat__send-button {
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
}

.chat__send-button:hover {
    cursor: pointer;
}

.chat__input {
    width: calc(100% - 60px);
    border: none;
    outline: none;
    height: 20px;
    border-radius: 8px;
    padding: 5px;
    resize: none;
}

.chat__input_darkTheme {
    background-color: var(--button-color-dark);
    color: var(--font-color-dark);
}

.chat__input_darkTheme::placeholder {
    color: var(--font-color-dark);
}

.chat__input_lightTheme {
    background-color: var(--button-color-light);
    color: var(--font-color-light);
}

.chat__input_lightTheme::placeholder {
    color: var(--font-color-light);
}

.chat__file-area {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 40px;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.chat__pinned-file:first-of-type {
    border-radius: 8px 8px 0 0;
}

.chat__pinned-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.chat__pinned-file_darkTheme {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.chat__pinned-file_lightTheme {
    background-color: var(--secondary-color-light);
    color: var(--font-color-light);
}

.chat__pinned-file img {
    width: 15px;
    height: 15px;
}

.chat__pinned-file img:hover {
    cursor: pointer;
}

.message__div {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px;
    width: 80%;
    margin-bottom: 10px;
}

.message__div:last-of-type {
    margin-bottom: 0;
}

.message__div_darkTheme {
    background-color: #484848;
    color: var(--font-color-dark);
}

.message__div_lightTheme {
    background-color: #fff;
    color: var(--font-color-light);
}

.message__info-block {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    opacity: .7;
}

.your-message {
    margin-left: auto;
}

.message__text-block {
    word-break: break-word;
}

.message_file-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 0px;
    user-select: none;
    -webkit-user-drag: none;
}

.message_file-div:hover {
    cursor: pointer;
}

.message_file-div img {
    width: 25px;
    height: 25px;
}