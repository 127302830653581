.incident-detail__block {
    width: calc(60% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    position: relative;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.incident-detail__block_darkTheme {
    background-color: var(--primary-color-dark);
}

.incident-detail__block_lightTheme {
    background-color: var(--primary-color-light);
}

.incident-detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    min-height: 35px;
    height: 35px;
    font-weight: bold;
    width: 100%;
}

.incident-detail__header span {
    margin-right: 10px;
}

.incident-detail__header_darkTheme {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.incident-detail__header_lightTheme {
    background-color: var(--secondary-color-light);
    color: #fff;
}

.incident-detail__initiator-block {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px;
    width: 45%;
}

.incident-detail__initiator-block_darkTheme {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.incident-detail__initiator-block_lightTheme {
    background-color: var(--block-color-light);
}

.incident-detail__initiator-row {
    display: flex;
    flex-direction: row;
}

.incident-detail__first-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    height: 20%;
}

.incident-detail__second-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(80% - 45px);
}

#first-column {
    width: 40%;
}

#second-column {
    width: 60%;
}

.incident-description__block {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;
    width: calc(55% - 20px);
}

.incident-description__block_darkTheme {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.incident-description__block_lightTheme {
    -webkit-box-shadow: inset 0px 0px 0px 1px #0000001a;
    box-shadow: inset 0px 0px 0px 1px #0000001a;
}

.incident-detail__left-block {
    width: 60%;
}

.incident-detail__chat-block {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
}

.incident-detail__switch {
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 8px 8px 0px 0px;
}

.incident-detail__switch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 50%;
    height: 30px;
}

.incident-detail__switch_darkTheme {
    background-color: var(--block-color-dark);
}

.incident-detail__switch_lightTheme {
    background-color: var(--secondary-color-light);
}

.incident-detail__switch-button_darkTheme {
    background-color: var(--button-color-dark);
    color: var(--font-color-dark);
}

.incident-detail__switch-button_lightTheme {
    background-color: var(--button-color-light);
    color: var(--font-color-light);
}

.incident-detail__select-row {
    display: grid;
    align-items: center;
    grid-template-columns: 130px 1fr;
    grid-template-rows: 1fr;
    padding-right: 10px;
}

.incident-detail__select-row p {
    width: 120px;
}

.incident-detail__select {
    width: calc(100% - 130px);
    height: 40px;
    border-radius: 8px;
}

.incident-detail__select option {
    height: 40px;
}

.incident-detail__select-row__p_darkTheme {
    color: var(--font-color-dark);
}

.incident-detail__select-row__p_lightTheme {
    color: var(--font-color-light);
}