.header {
    width: calc(100% - 60px);
    height: 55px;
    min-height: 55px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    user-select: none;
    -webkit-user-drag: none;
}

.header__logo-block {
    display: flex;
    flex: 1;
}

.header__exit-button-block {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    user-select: none;
    -webkit-user-drag: none;
}



.header__exit-button-block span {
    color: var(--font-color);
}

.header_darkTheme {
    background: var(--primary-color-dark);
}

.header_lightTheme {
    background: var(--primary-color-light);
}

.header__body {
    width: calc(100% - 80px);
    height: 55px;
    padding: 0px 20px;
    margin-top: 5px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    -webkit-user-drag: none;
}

.header__body_darkTheme {
    background-color: var(--primary-color-dark);
    color: var(--font-color-dark);
}

.header__body_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.header__exit-button {
    width: 25px;
    height: 25px;
    user-select: none;
    -webkit-user-drag: none;
}

.header__exit-button:hover {
    cursor: pointer;
}

.header__theme-button {
    width: 25px;
    height: 25px;
}

.header__theme-button:hover {
    cursor: pointer;
}


.header__button-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex: 0 1 auto;
}

.header__button {
    border: none;
    background-color: transparent;
    height: 40px;
    padding: 0px 8px;
    font-weight: normal;
    transition: .2s ease-in-out;
    font-size: 16px;
    opacity: .7;
    user-select: none;
    -webkit-user-drag: none;
}

.header__button:hover {
    cursor: pointer;
    opacity: 1;
}

.header__button_lightTheme {
    color: var(--font-color-light);
}

.header__button_darkTheme {
    color: var(--font-color-dark);
}

.header__button_darkTheme_active {
    opacity: 1;
}

.header__button_lightTheme_active {
    opacity: 1;
}

.header__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9999;
    border-radius: 0px 0px 8px 8px;
    padding-top: 20px;
    padding-bottom: 8px;
}

.header__menu__layout {
    height: 100dvh;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0px;
    z-index: 8888;
    background-color: rgba(0, 0, 0, 0.37)
}

.header__menu_darkTheme {
    background-color: var(--primary-color-dark);
}

.header__menu_lightTheme {
    background-color: var(--primary-color-light);
}

.header__menu-item {
    display: flex;
    flex-direction: row;
    padding: 8px 20px;
    align-items: center;
    gap: 15px;
    user-select: none;
    -webkit-user-drag: none;
}

.header__menu-item span {
    user-select: none;
    -webkit-user-drag: none;
}

.header__menu-item svg {
    width: 25px;
    height: 25px;
}

.header__menu-item_darkTheme {
    color: var(--font-color-dark);
}

.header__menu-item_lightTheme {
    color: var(--font-color-light);
}

#checkbox {
    display: none;
}

.toggle {
    position: relative;
    width: 30px;
    cursor: pointer;
    display: block;
    height: calc(2px * 3 + 8px * 2);
}

.bar {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: calc(4px / 2);
    background: var(--header-menu-icon-color);
    color: inherit;
    opacity: 1;
    transition: none 0.2s ease-in-out 0s;
}

.bar--top {
    bottom: calc(50% + 8px + 2px/ 2);
    transition-property: bottom, transform;
    transition-delay: calc(0s + 0.35s), 0s;
}

.bar--middle {
    top: calc(50% - 2px/ 2);
    transition-property: opacity;
    transition-delay: calc(0s + 0.35s);
}

.bar--bottom {
    top: calc(50% + 8px + 2px/ 2);
    transition-property: top, transform;
    transition-delay: calc(0s + 0.35s), 0s;
}

#checkbox:checked+.toggle .bar--top {
    bottom: calc(50% - 2px/ 2);
    transform: rotate(135deg);
    transition-delay: 0s, calc(0s + 0.35s);
}

#checkbox:checked+.toggle .bar--middle {
    opacity: 0;
    transition-duration: 0s;
    transition-delay: calc(0s + 0.35s);
}

#checkbox:checked+.toggle .bar--bottom {
    top: calc(50% - 2px/ 2);
    transform: rotate(225deg);
    transition-delay: 0s, calc(0s + 0.35s);
}

@media screen and (max-width: 767px) {
    .header {
        width: calc(100% - 40px);
        padding: 0px 20px;
        box-shadow: var(--chat-shadow-color);
    }
}