.base-input {
    padding: 12px;
    height: 20px;
    border-radius: 12px;
    outline: none;
    border: none;
    transition: .2s ease-in-out;
    font-family: 'Circe';
    font-size: 16px;
    line-height: 16px;
    width: calc(100% - 24px);
    opacity: .6;
    box-shadow: var(--border-color) 0px 0px 0px 1px inset;  
}

.base-input:hover {
    box-shadow: var(--border-color-hover) 0px 0px 0px 1px inset;
}

.base-input:focus {
    opacity: 1;
}

.base-input:focus::placeholder {
    opacity: 0;
}

.base-input_lightTheme {
    box-shadow: var(--secondary-color-light) 0px 0px 0px 1px inset;
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.base-input_lightTheme::placeholder {
    color: var(--font-color-light);
}

.base-input_lightTheme:focus {
    box-shadow: var(--secondary-color-light) 0px 0px 0px 1px inset;
}

.base-input_darkTheme {
    box-shadow: #fff9 0px 0px 0px 1px inset;
    background-color: var(--secondary-color-dark);
    color: var(--font-color-dark);
}

.base-input_darkTheme::placeholder {
    color: var(--font-color-dark);
}

.base-input_darkTheme:focus {
    box-shadow: #fff9 0px 0px 0px 1px inset;
}
