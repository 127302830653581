.incidents-page__block {
    width: calc(100% - 100px);
    height: calc(100% - 50px);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    max-width: 1800px;
    max-height: 1000px;
    background-color: var(--transparent-background-color);
}

/* .incidents-page__block_darkTheme {
    background-color: var(--primary-color-dark);
}

.incidents-page__block_lightTheme {
    background-color: var(--primary-color-light);
} */

@media screen and (max-width: 767px) {
    .incidents-page__block {
        width: 100%;
        max-height: none;
        height: 100%;
        background-color: transparent;
        align-items: center;
    }

}
