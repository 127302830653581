.phone-input__div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}

.phone-input__div_darkTheme {
    color: var(--font-color-dark);
}

.phone-input__div_lightTheme {
    color: var(--font-color-light);
}

.phone-input {
    border-radius: 10px 0px 0px 10px;
    height: 23px;
    padding: 10px;
    font-size: 15px;
}

.phone-input:hover {
    border-color: var(--border-color-hover);
}

.phone-input:focus {
    outline: none;
}

.phone-input_darkTheme:focus {
    border-color: var(--border-color-focus);
}

.phone-input_lightTheme:focus {
    border-color: var(--border-color-focus);
}

.phone-input__darkTheme:disabled {
    background-color: var(--primary-color-dark) !important;
}

.phone-input__lightTheme:disabled {
    background-color: var(--primary-color-light) !important;
}

.phone-input {
    border: 1px solid var(--border-color);
    background-color: var(--input-background-color) !important;
    color: var(--font-color);
    transition: .3s ease-in-out;
    width: 135px;
    border-radius: 10px 0px 0px 10px;
    border-right: none;
}



.phone-input__label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.phone-input__input-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.phone-input__div_darkTheme .phone-input__change-button {
    background-color: rgb(61, 61, 61);
}

.phone-input__change-button {
    width: 95px;
    background-color: transparent;
    height: 45px;
    border: none;
    font-size: 15px;
    color: var(--deny-button-text-color);
    border-radius: 0px 10px 10px 0px;
    font-weight: bold;
    font-size: 16px;
    border: 2px solid var(--phone-button-border-color);
}

@media screen and (max-width: 767px) {
    .phone-input__div {
        flex-direction: column;
    }

    .phone-input__input-div {
        width: 100%;
    }

    .phone-input {
        width: calc(50% - 20px);
    }

    .phone-input__change-button {
        width: 50%;
    }
}