.button {
    border: none;
    border-radius: 8px;
    height: 40px;
    font-family: 'Circe';
    transition: .3s ease-in-out;
    font-size: 15px;
    font-weight: bold;
}

.button:hover {
    cursor: pointer;
    opacity: .8;
}

.button_darkTheme {
    background-color: var(--button-color-dark);
    color: #fff;
}

.button_lightTheme {
    background-color: var(--button-color-light);
    color: var(--font-color-light);
}