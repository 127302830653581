.incident-list__block {
    width: 40%;
    height: 100%;
    max-height: 100%;
    border-radius: 10px 0 0 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.incident-list__block_darkTheme {
    background-color: var(--secondary-color-dark);
}

.incident-list__block_lightTheme {
    background-color: var(--primary-color-light-tr);
}

.incident-list__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    padding: 0px 20px;
    height: 50px;
    min-height: 50px;
}

.incident-list__header__switch-item {
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
}

.incident-list__header__switch-item:hover {
    cursor: pointer;
    opacity: .7;
}

.incident-list__header__switch-item_darkTheme:after {
    content: '';
    margin-left: 20px;
    border-right: 1px solid var(--font-color-dark);
}


.incident-list__header__switch-item_lightTheme:after {
    content: '';
    margin-left: 20px;
    border-right: 1px solid var(--font-color-light);
}

.incident-list__header__switch-block {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.incident-list__header__switch-item_darkTheme {
    color: var(--font-color-dark);
}

.incident-list__header__switch-item_lightTheme {
    color: var(--font-color-light);
}

.incident-list__header__toggle-block {
    user-select: none;
    -webkit-user-drag: none;
}

.incident-list__header__toggle-block_darkTheme {
    color: var(--font-color-dark);
}

.incident-list__header__toggle-block_lightTheme {
    color: var(--font-color-light);
}

.incident-list__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 5px;
    max-height: 128px;
    min-height: 128px;
    overflow: hidden;
    transition: .2s ease-in-out;
}

.incident-list__item:hover {
    cursor: pointer;
}

.incident-list__item_darkTheme {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.incident-list__item_darkTheme_active {
    background-color: var(--button-color-dark);
    color: var(--font-color-dark);
}

.incident-list__item_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.incident-list__item_lightTheme_active {
    background-color: var(--secondary-color-light);
    color: #fff;
}

.incident-list__item__first-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.incident-list__item__date-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
}

.incident-list__item__second-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
}

#user__full-name {
    width: 60%;
    font-size: 16px;
}

#incident__description {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.incident-list__item__detail-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
}

.incident-list__item__ext-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 40%;
}

.incident-list__item__tags-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    gap: 5px;
}

.incident-list__item__tag {
    border-radius: 6px;
    padding: 0px 8px;
}

.incident-list__item__tag_darkTheme {
    background-color: var(--button-color-dark);
    color: var(--font-color-dark);
}

.incident-list__item__tag_lightTheme {
    background-color: var(--block-color-light);
    color: var(--font-color-light);
}



.loading-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 5px;
    max-height: 128px;
    min-height: 128px;
    overflow: hidden;
    transition: .2s ease-in-out;
    position: relative;
}

.loading-row:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 2s infinite;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* IE10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
}


@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}