.notification-center {
    max-width: 1800px;
    max-height: 1000px;
    width: calc(100% - 100px);
    height: calc(100% - 40px);
    border-radius: 10px;
    background-color: var(--block-color);
    color: var(--font-color);
    overflow: hidden;
}

.notification-center__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.notification-center__header h2 {
    font-size: 20px;
}

.notification-center__table {
    display: flex;
    flex-direction: column;
    height: calc(100% - 55px);
    width: 100%;
}

.notification-toast-container {
    position: fixed;
    top: 50px;
    right: 10px;
    z-index: 1000;
}

.empty-notifications {
    display: flex;
    width: 100%;
    height: calc(100% - 55px);
    justify-content: center;
    align-items: center;
}

.notification {
    background-color: var(--block-color);
    color: var(--font-color);
    padding: 15px;
}

.notification-toast {
    background-color: var(--list-item-color);
    color: var(--font-color);
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.clear-button {
    background-color: transparent;
    color: var(--font-color);
}

.notification.error {
    border-color: #ff4d4f;
}

.notification.success {
    border-color: #52c41a;
}

.notification-toast-container {
    position: fixed;
    z-index: 1000;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .notification-toast-container {
        top: 50px;
        right: 10px;
        width: auto;
    }
}

@media (max-width: 767px) {
    .notification-toast-container {
        top: 0;
        right: 0;
        left: 0;
        max-width: none;
    }

    .notification-center {
        width: 100%;
        height: 100%;
    }
}


.notification-toast__second-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color-tr);
    font-size: 14px;
}

.view-center-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: var(--font-color);
    fill: var(--font-color);
}

.view-center-button svg {
    color: var(--font-color);
    fill: var(--font-color);
    width: 15px;
    height: 15px;
    transform: rotate(180deg);
}

.view-center-button:hover {
    cursor: pointer;
}

/* Стили для уведомлений разных типов */
/* .notification-toast.info {
    background-color: #007bff;
}

.notification-toast.success {
    background-color: #28a745;
}

.notification-toast.error {
    background-color: #dc3545;
} */