.container {
    display: flex;
    flex-direction: row;
}

.file-input__button {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.file-input_clip {
    margin-right: 10px;
    transition: .3s ease-in-out;
    width: 25px;
    color: var(--font-color);
}

.file-input_clip:hover {
    cursor: pointer;
    opacity: .7;
}

.file-input__file {
    max-height: 30px;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    font-size: 14px;
    font-weight: 100 !important;
    border: 1px solid #000;
    border-radius: 6px;
    padding: 5px 10px;
    z-index: 999;
    margin-right: 5px;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

}

.file-input__p {
    margin: 0;
    color: #000;
    user-select: none;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.file-input_cross {
    width: 15px;
    height: 15px;
    transition: .3s ease-in-out;
}

.file-input_cross:hover {
    cursor: pointer;
    opacity: .7;
}

.incident-popup__button-div .os-scrollbar-track {
    height: 5px;
    margin-top: 2px;
}
