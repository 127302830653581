.auth-page__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 15px;
    padding: 35px;
    max-width: 350px;
    text-align: center;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.auth-page__block_darkTheme {
    background-color: var( --primary-color-dark);
    color: var(--font-color-dark);
}

.auth-page__block_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.auth-page__block img {
    width: 75px;
    height: 75px;
}

@supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
    .tick {
       stroke-opacity: 0;
       stroke-dasharray: 29px;
       stroke-dashoffset: 29px;
       animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
       animation-delay: .6s
   }

   .circle {
       fill-opacity: 0;
       stroke: #219a00;
       stroke-width: 16px;
       transform-origin: center;
       transform: scale(0);
       animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;   
   }   
}

@keyframes grow {
   60% {
       transform: scale(.8);
       stroke-width: 4px;
       fill-opacity: 0;
   }
   100% {
       transform: scale(.9);
       stroke-width: 8px;
       fill-opacity: 1;
       fill: #219a00;
   }
}

@keyframes draw {
   0%, 100% { stroke-opacity: 1; }
   100% { stroke-dashoffset: 0; }
}