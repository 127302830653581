.user-incident-list__block {
    width: 50%;
    height: 100%;
    max-height: calc(100% - 10px);
    border-radius: 10px 0 0 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}


.user-incident-list__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 60px);
    padding: 10px 30px 5px 30px;
    gap: 10px;
    height: 55px;
    min-height: 55px;
}


.user-incident-list__header__switch-item {
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
}

.user-incident-list__header__switch-item:hover {
    cursor: pointer;
    opacity: .7;
}

.user-incident-list__header__switch-item_darkTheme:after {
    content: '';
    margin-left: 20px;
    border-right: 1px solid var(--font-color-dark);
}


.user-incident-list__header__switch-item_lightTheme:after {
    content: '';
    margin-left: 20px;
    border-right: 1px solid var(--font-color-light);
}

.user-incident-list__header__switch-block {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.user-incident-list__header__switch-item_darkTheme {
    color: var(--font-color-dark);
}

.user-incident-list__header__switch-item_lightTheme {
    color: var(--font-color-light);
}

.user-incident-list__header__toggle-block {
    display: flex;
    justify-content: center;
    gap: 10px;
    user-select: none;
    -webkit-user-drag: none;
    align-items: center;
}

.user-incident-list__header__toggle-block_darkTheme {
    color: var(--font-color-dark);
}

.user-incident-list__header__toggle-block_lightTheme {
    color: var(--font-color-light);
}

.user-incident-list__item {
    display: flex;
    padding: 15px 30px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 5px;
    height: 50px;
    border-radius: 8px;
    background-color: var(--list-item-color);
    color: var(--font-color);
    transition: .2s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
}

.user-incident-list__item:last-of-type {
    margin-bottom: 0px;
}

.user-incident-list__item_active {
    background-color: var(--block-color-active);
}

.user-incident-list__item:hover {
    cursor: pointer;
    background-color: var(--block-color-hover);
    color: var(--font-color);
    -webkit-transition: background-color .1 linear;
    -ms-transition: background-color .1 linear;
    transition: background-color .1 linear;
}

.user-incident-list__item_active:hover {
    background-color: var(--block-color-active);
    color: var(--font-color);
}



.user-incident-list__item_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}


.user-incident-list__item__first-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.user-incident-list__item__date-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
}

.user-incident-list__item__second-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
}

#user__full-name {
    width: 60%;
    font-size: 16px;
}

#incident__description {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.user-incident-list__item__detail-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
}

.user-incident-list__item__ext-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 40%;
}

.user-incident-list__item__tags-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    gap: 5px;
}

.user-incident-list__item__tag {
    border-radius: 6px;
    padding: 0px 8px;
}

.user-incident-list__item__tag_darkTheme {
    background-color: var(--button-color-dark);
    color: var(--font-color-dark);
}

.user-incident-list__item__tag_lightTheme {
    background-color: var(--block-color-light);
    color: var(--font-color-light);
}

.user-incident-list__header__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    gap: 10px;
}

.user-incident-list__header__button_darkTheme {
    background-color: #4B4B4B;
    color: #fff;
}

.user-incident-list__header__button_lightTheme {
    background-color: #0082C8;
    color: #fff;
}

.user-incident-list__item__cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 5px;
}


.user-incident-list__item__cell span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


#first-cell {
    display: flex;
    flex-direction: column;
    min-width: 75px;
    width: 75px;
}

#second-cell {
    display: flex;
    flex-direction: column;
}

.right-arrow-container {}

.user-loading-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    margin-bottom: 5px;
    overflow: hidden;
    transition: .2s ease-in-out;
    position: relative;
    padding: 10px 20px;
    max-height: 60px;
    min-height: 60px;
    width: calc(100% - 40px);
}

.user-incident-list__empty-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 55px);
    color: var(--font-color-tr);
    text-align: center;
}

.user-incident-list__error-block {
    display: flex;
    flex-direction: row;
    width: calc(100% - 64px);
    color: var(--font-color);
    border: 2px solid #C7000E;
    border-radius: 8px;
    padding: 10px 20px;
    align-items: center;
    gap: 20px;
    height: 50px;
    position: absolute;
    background-color: var(--list-item-color);
}

.user-incident-list__error-block h2 {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
}

.user-incident-list__error-block p {
    overflow: hidden;
    color: var(--font-color-tr);
    text-overflow: ellipsis;
    font-size: 14px;
}

.user-incident-list__error-block svg:last-of-type {
    margin-left: auto;
}

.user-loading-row:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 2s infinite;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.4)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* IE10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
}


@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

@media screen and (max-width: 2550px) {
    .user-incident-list__item {
        padding: 10px 20px;
    }

    .user-loading-row {
        width: calc(100% - 20px);
        padding: 10px;
        max-height: 50px;
        min-height: 50px;
    }
}

@media screen and (max-width: 955px) {
    .user-incident-list__item {
        gap: 5px;
    }

}

@media screen and (max-width: 767px) {
    .user-incident-list__block {
        width: 100%;
        height: calc(100% - 20px);
        border-radius: 10px;
        gap: 0px;
        align-items: center;
    }

    .user-incident-list__block {
        border-radius: 0px;
        gap: 10px;
        min-height: calc(100% - 20px);
    }

    .user-incident-list__item {
        border-radius: 8px;
        margin: 0px;
        gap: 20px;
        background-color: var(--list-item-color);
        margin-bottom: 10px;
        box-shadow: var(--chat-shadow-color);
        padding: 15px;
        width: calc(100% - 30px);
    }

    .user-incident-list__item:hover {
        background-color: var(--list-item-color);
    }

    .user-incident-list__header {
        height: 50px;
        min-height: 50px;
        padding: 0px;
        width: calc(100% - 50px);
        padding: 0px 15px;
        z-index: 888;
        border-radius: 8px;
        background-color: var(--list-item-color);
        box-shadow: var(--chat-shadow-color);
    }

    .user-incident-list__header_lightTheme {
        background-color: #fff;
    }

    .user-incident-list__header_darkTheme {
        background-color: #383838;
    }
}