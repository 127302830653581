.knowledge__main-block {
    width: calc(100% - 100px);
    height: calc(100% - 40px);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    max-width: 1800px;
    max-height: 1000px;
}

.knowledge-table {
    border-radius: 0 10px 10px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-template-rows: repeat(auto-fill, 330px);
    gap: 10px;
    position: relative;
    padding: 10px;
    width: calc(100% - 20px);
    margin: 0px;
}

.no-results {
    color: var(--font-color);
}


.knowledge-table__item {
    display: flex;
    flex-direction: column;
    background-color: var(--list-item-color);
    border-radius: 12px;
    color: var(--font-color);
    padding: 10px;
    gap: 10px;
    max-width: 100%;
}

.knowledge-table__item:hover {
    cursor: pointer;
}

.knowledge-table__item img {
    width: 100%;
    border-radius: 2px;
    object-fit: cover;
    filter: var(--image-filter);
    height: 50%;
    user-select: none;
    -webkit-user-drag: none;
}

.knowledge-table__item__text-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: left;
    gap: 10px;
    height: 50%;
    justify-content: space-between;
}

.knowledge-table__item__text-block div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.knowledge-table__overlay {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    width: 60%;
    background-color: var(--transparent-background-color);
    border-radius: 0px 22px 22px 0px;
}

.knowledge-search__div {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 10px;
    padding-right: 0px;
    border-radius: 22px 0px 0px 22px;
    gap: 10px;
    background-color: var(--transparent-background-color);
}

.knowledge-search__input-div {
    position: relative;
}

.knowledge-search__input {
    padding: 10px 20px;
    height: 25px;
    border-radius: 12px;
    outline: none;
    border: none;
    transition: .2s ease-in-out;
    font-family: 'Circe';
    font-size: 16px;
    line-height: 16px;
    width: calc(100% - 40px);
    color: var(--border-color);
    background-color: var(--list-item-color);
}

.knowledge-search__input:hover {
    border-color: var(--border-color-hover);
    color: var(--border-color-hover);
}

.knowledge-search__input:focus {
    border-color: var(--block-color-focus);
    color: var(--border-color-focus);
}

.knowledge-search__input::placeholder {
    color: var(--border-color);
}

.knowledge-search__input-div svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 10px;
    right: 20px;
}

.knowledge-search__categories-div {
    display: flex;
    flex-direction: column;
    color: var(--font-color);
    gap: 10px;
    padding-bottom: 0px;
    background-color: var(--list-item-color);
    border-radius: 10px;
    user-select: none;
    -webkit-user-drag: none;
}

.knowledge-search__item {
    color: var(--font-color);
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    transition: background-color .3s ease-in-out;
    text-align: left;
    gap: 10px;
}

.knowledge-search__item:last-of-type {
    margin-bottom: 10px;
}

.knowledge-search__item:hover {
    background-color: var(--block-color-hover);
    cursor: pointer;
}


.knowledge-search__item_active:hover {
    cursor: default;
    background-color: var(--block-color-active);
}

.knowledge-search__item_active {
    background-color: var(--block-color-active);
}

.knowledge-search__item svg {
    width: 24px;
    height: 24px;
}

.knowledge-search__item span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.knowledge-table__item h2 {
    font-size: 16px;
}

.knowledge-search__categories-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.knowledge-search__categories-header svg {
    width: 15px;
    height: 15px;
}

.knowledge-search__categories-header:hover {
    cursor: pointer;
}

.rotate-icon-opened {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.rotate-icon-closed {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}

.rpv-core__viewer {
    height: calc(100% - 50px) !important;
}

.knowledge-table__item__download-button {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background-color: var(--block-color-active);
}

.knowledge-table__item__download-button svg {
    width: 20px;
    height: 20px;
    border-radius: 0px;
}


@media screen and (max-width: 767px) {
    .knowledge-table__item {
        width: calc(100% - 20px);
        box-shadow: var(--chat-shadow-color);
    }

    .knowledge__main-block {
        width: 100%;
        max-height: 100%;
        height: 100%;
        flex-direction: column;
        border-radius: 0px;
        gap: 10px;
        align-items: center;
        margin-top: 10px;
    }

    .knowledge-search__div {
        width: calc(100% - 20px);
        border-radius: 10px;
        padding: 0px;
        background-color: transparent;
        box-shadow: var(--chat-shadow-color);
    }

    .knowledge-table {
        grid-template-columns: 1fr;
        border-radius: 0px;
        padding: 10px;
        padding-top: 0px;
        width: calc(100% - 20px);
    }


    .knowledge-table__overlay {
        border-radius: 0px;
        width: 100%;
        background-color: transparent;
    }

    .knowledge-search__categories-div {
        padding: 0px;
    }
}