.rate-popup__layout {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.37);
    z-index: 999;
}

.rate-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    gap: 10px;
    max-width: 245px;
    background-color: var(--rate-popup-color);
}

.rate-popup__subtitle {
    color: var(--font-color-tr);
    text-align: center;
    font-size: 14px;
    text-wrap: wrap !important;
    white-space: wrap !important;
}
.rate-popup textarea {
    resize: none;
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 8px;
    height: 75px;
    font-size: 16px;
    transition: .3s ease-in-out;
    border-color: var(--border-color);
}

.rate-popup textarea::placeholder{
    color: var(--font-color-tr);
}

.rate-popup textarea:focus {
    border-color: var(--border-color-focus);
}

.rate-popup textarea:hover {
    border-color: var(--border-color-hover);
}

.rate-popup textarea:focus::placeholder{
    color: var(--font-color);
}

.rate-popup_darkTheme textarea {
    background-color: var(--block-color-dark);
    color: var(--font-color-dark);
}

.rate-popup_lightTheme textarea {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.rate__star {
    width: 35px;
    transition: color 0.3s;
}

.rate__star--hover {
    color: #FFDF00 !important;
}

.rate__star--default {
    color: transparent !important;
}


.rate__star:hover {
    cursor: pointer;
}

.rate__stars-div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0px;
}


.rate-popup__close-button:hover {
    cursor: pointer;
    justify-self: flex-end;
}

.rate-popup__submit-button {
    font-size: 16px;
    display: flex;
    width: 100%;
    height: 35px;
    padding: 5px 16px 4px 16px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--button-color);
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.30);
    border: none;
    color: var(--button-text-color);
    font-weight: bold;
}

.rate__apply-button {
    width: 115px;
    height: 35px;
    border-radius: 6px;
    background: var(--button-color);
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: var(--button-text-color);
}

.rate__deny-button {
    width: 115px;
    height: 35px;
    border-radius: 6px;
    background: transparent;
    border: 2px solid var(--button-color);
    font-size: 16px;
    font-weight: bold;
    color: var(--deny-button-text-color);
}

.rate__buttons-div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
}

.rate__stars-div-det {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 5px;
}

.rate__star-det {
    user-select: none;
    -webkit-user-drag: none;
}

.rate__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    user-select: none;
    -webkit-user-drag: none;
    width: 100%;
}

.rate__comment-block {
    display: 'flex';
    flex-direction: 'row';
    gap: '10px';
    align-items: 'center';
}

.rate__comment-block:hover {
    cursor: pointer;
}

.rate__comment {
    display: flex;
    position: absolute;
    width: calc(100% - 40px);
    left: 0px;
    top: 65px;
    z-index: 999;
    padding: 15px 20px;
    border-radius: 8px;
    margin-top: 5px;
}

.rate__comment_darkTheme {
    background-color: #2E2E2E;
    color: var(--font-color-dark);
}

.rate__comment_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

@media screen and (max-width: 767px) {
    .rate__comment {
        top: 85px;
        justify-content: center;
    }

    .rate__block {
        margin-left: 0;
    }
}