@font-face {
  font-family: 'Circe';
  src: url('./utils/fonts/Circe-Regular.woff');
  src: local('Circe'), local('Circe-Regular'),
    url('./utils/fonts/Circe-Regular.woff2') format('woff2'),
    url('./utils/fonts/Circe-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('./utils/fonts/Circe-Bold.woff');
  src: local('Circe Bold'), local('Circe-Bold'),
    url('./utils/fonts/Circe-Bold.woff2') format('woff2'),
    url('./utils/fonts/Circe-Bold.woff') format('woff');
  font-weight: bold;
}

/* @font-face {
  font-family: 'Circe';
  src: url('./utils/fonts/Circe-Light.woff');
  src: local('Circe Light'), local('Circe-Light'),
      url('./utils/fonts/Circe-Light.woff2') format('woff2'),
      url('./utils/fonts/Circe-Light.woff') format('woff');
  font-weight: thin;
  font-style: normal;
} */


* {
  font-family: 'Circe';
  padding: 0;
  margin: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

body.lightTheme {
  --background-color: #ffffff;
  --chat-overlay-background-tr: #fff;
  --transparent-background-color: rgba(255, 255, 255, .4);
  --button-color: #0082C8;
  --button-text-color: #fff;
  --box-shadow: '0px 0px 21.3px 0px rgba(0, 130, 200, 0.20)';
  --font-color: #000;
  --font-color-tr: rgba(0, 0, 0, .5);
  --block-color: #fff;
  --block-color-hover: rgba(255, 255, 255, .8);
  --block-color-active: #F6F6F6;
  --switch-color-checked: #0082C8;
  --switch-color-unchecked: #9aa6ad;
  --second-block-color: #9aa6ad;
  --list-item-color: #fff;
  --chat-background: url(./utils/images/chat-background_blue.svg);
  --detail-background: url(./utils/images/detail-background_blue.svg);
  --border-color: rgba(0, 0, 0, .4);
  --border-chat-overlay-color: rgba(0, 130, 200, 0.30);
  --border-color-focus: rgba(0, 0, 0, 1);
  --border-color-hover: rgba(0, 0, 0, .7);
  --input-background-color: #fff;
  --header-menu-icon-color: #0082C8;
  --phone-button-border-color: #0082C8;
  --calendar-day-active-color: #0082C8;
  --chat-overlay: #0082C808;
  --image-filter: none;
  --solution-background-color: #EDFAE1;
  --rate-popup-color: #fff;
  --deny-button-text-color: #0082C8;
  --chat-shadow-color: 0px 0px 12px 0px rgba(0, 130, 200, 0.15);
  --service-item-color-hover: rgba(0, 0, 0, 0.007);
  --chat-message-color: #fff;
}

body.darkTheme {
  --background-color: #000000;
  --chat-overlay-background-tr: #2E2E2E;
  --transparent-background-color: rgba(0, 0, 0, .3);
  --button-color: #4B4B4B;
  --button-text-color: #FFFFFFCC;
  --box-shadow: 0px 0px 21.3px 0px rgba(0, 0, 0, 0.20);
  --font-color: #FFFFFFCC;
  --font-color-tr: rgba(255, 255, 255, .5);
  --block-color: #121212;
  --block-color-hover: #595959;
  --block-color-active: #383838;
  --switch-color-checked: #4B4B4B;
  --switch-color-unchecked: #2F2F2F;
  --list-item-color: #2E2E2E;
  --second-block-color: #3d3d3d;
  --chat-background: url(./utils/images/chat-background_grey.svg);
  --detail-background: url(./utils/images/detail-background_grey.svg);
  --border-color: rgba(255, 255, 255, .4);
  --border-chat-overlay-color: rgba(75, 75, 75, 0.60);
  --border-color-focus: rgba(255, 255, 255, .8);
  --border-color-hover: rgba(255, 255, 255, .7);
  --input-background-color: #3d3d3d;
  --header-menu-icon-color: #FFFFFFCC;
  --phone-button-border-color: rgba(255, 255, 255, .4);
  --calendar-day-active-color: #2F2F2FCC;
  --chat-overlay: transparent;
  --image-filter: brightness(0.8);
  --solution-background-color: #2B3B2C;
  --rate-popup-color: #252525;
  --deny-button-text-color: #FFFFFFCC;
  --chat-shadow-color: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  --service-item-color-hover: rgba(255, 255, 255, 0.08);
  --chat-message-color: #383838;
}

body {
  background-color: var(--block-color);
}

textarea:disabled:hover {
  border-color: var(--border-color);
}

input:disabled {
  opacity: .4;
}

input:disabled:hover {
  cursor: not-allowed;
}

:root {

  --font-color-dark: rgba(255, 255, 255, .8);
  --font-color-light: #000;

  --accent-color-dark: #fff9;
  --accent-color-pridex: #0082CA;

  --primary-color-dark: #121212;
  --primary-color-light: #fff;
  --primary-color-light-tr: #fff6;

  --secondary-color-dark: #12121266;
  --secondary-color-light: #9aa6ad;

  --block-color-light: #ebedef;
  --block-color-dark: #3d3d3d;

  --button-color-dark: #707070;
  --button-color-light: #fff;

  --border-color-light: #B3B3B3;
  --border-color-dark: #fff9;
  --border-focus-color-light: #0082CA;
  --border-focus-color-dark: #0082CA;
}


.App {
  font-family: "Circe";
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.page-container {
  font-family: "Circe";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 50px);
  width: 100%;
}

.App_darkTheme {
  background: #222;
  /* background: radial-gradient(circle, rgba(0,130,200,1) 0%, rgba(18,18,18,1) 100%); */
  /* background: linear-gradient(45deg, #000000 0%, #434343 100%); */
}

.App_lightTheme {
  /* background: rgb(0,130,200);
  background: radial-gradient(circle, rgba(0,130,200,1) 0%, rgba(255,255,255,1) 100%); */
  background: linear-gradient(45deg, #0082CA 0%, #DFF0FA 100%);
}

.p_darkTheme {
  color: var(--font-color-dark);
}

.p_lightTheme {
  color: var(--font-color-light);
}

textarea:focus {
  outline: none;
  font-size: 16px;
}

textarea::placeholder {
  font-size: 16px;
}

.popup__layout {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.37);
  z-index: 999;
}

.pdf-popup__body {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  max-width: 875px;
  border-radius: 10px;
  background-color: var(--block-color);
  height: calc(100% - 40px);
  gap: 15px;
  padding-bottom: 10px;
  overflow: hidden;
}

.pdf-popup__body .popup__close-button {
  margin: 20px 20px 0px 0px;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  opacity: .5;
}

button:disabled:hover {
  opacity: .5;
  cursor: not-allowed;
}

#login-page-containter {
  height: 100%;
}

ul {
  padding-left: 25px;
}

li::marker {
  color: #0082CA;
  font-size: 18px;
}

textarea:disabled {
  opacity: .4;
}

.vanilla-calendar {
  z-index: 9999;
  font-family: inherit;
}

.checkbox-ios {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 16px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 16px;
  user-select: none;
}

.checkbox-ios .checkbox-ios-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 36px;
  height: 16px;
  border: 1px solid transparent;
  border-radius: 25%/50%;
  vertical-align: top;
  background: var(--switch-color-unchecked);
  transition: .2s ease-in-out;
}

.checkbox-ios .checkbox-ios-switch:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
  transition: .15s ease-in-out;
}

.checkbox-ios input[type=checkbox] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-ios input[type=checkbox]:not(:disabled):active+.checkbox-ios-switch:before {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
}

.checkbox-ios input[type=checkbox]:checked+.checkbox-ios-switch {
  background: var(--switch-color-checked);
}

.checkbox-ios input[type=checkbox]:checked+.checkbox-ios-switch:before {
  transform: translateX(20px);
}

/* Hover */
.checkbox-ios input[type="checkbox"]:not(:disabled)+.checkbox-ios-switch {
  cursor: pointer;
  border-color: transparent;
}

/* Disabled */
.checkbox-ios input[type=checkbox]:disabled+.checkbox-ios-switch {
  filter: grayscale(70%);
  border-color: transparent;
}

.checkbox-ios input[type=checkbox]:disabled+.checkbox-ios-switch:before {
  background: #eee;
}

/* Focus */
.checkbox-ios.focused .checkbox-ios-switch:before {
  box-shadow: inset 0px 0px 4px #ff5623;
}

input:-webkit-autofill {
  animation-name: autofill-reset;
  animation-fill-mode: both;
}

@keyframes autofill-reset {
  0% {
    background-color: transparent;
    color: inherit;
  }

  100% {
    background-color: transparent;
    color: inherit;
  }
}


@keyframes slide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}



@media screen and (max-width: 767px) {
  .App_darkTheme {
    background-color: var(--block-color-dark);
    background: none;
  }

  .App_lightTheme {
    background-color: #fff;
    background: none;
  }

  .page-container {
    justify-content: normal;
    height: calc(100% - 50px);
  }

  .pdf-popup__body {
    height: auto;
    max-height: calc(100% - 40px);
  }
}