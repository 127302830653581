.solution__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #000;
    align-items: center;
}


.solution__overlay {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    width: calc(100% - 40px);
    color: var(--font-color);
    background-color: var(--solution-background-color);
    border-radius: 8px;
    word-break: break-word;
    max-height: 150px;
}

.solution__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 24px;
    user-select: none;
    -webkit-user-drag: none;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    justify-content: space-between; 
}

.solution__header svg {
    width: 15px;
    height: 15px;
}

.solution__header:hover {
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .solution__block {
        width: calc(100% - 20px);
    }
    .solution__overlay {
        width: calc(100% - 30px);
        padding: 15px;
    } 
}