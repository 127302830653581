.user-chat__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-image: var(--chat-background);
    background-color: var(--block-color);
}

.user-chat__block__overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-color: var(--chat-overlay);
}

.user-chat__main-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: flex-end;
}

.user-chat__input-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    background-color: transparent;
    background-size: cover;
}


.user-chat__input-block__row {
    align-items: center;
    border-radius: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 5px 10px;
    width: calc(100% - 20px);
    max-width: 700px;
    z-index: 2;
}

.user-chat__input-row {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    width: 100%;
    z-index: 2;
}

.user-chat__input::-webkit-scrollbar {
    display: none;
}

.user-chat__pin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0px;
    width: 25px;
    height: 40px;
    border: none;
    align-self: flex-end;
}

.user-chat__pin-button svg {
    width: 100%;
    height: 100%;
}


.user-chat__pin-button input {
    display: none;
}

.user-chat__pin-button:hover {
    cursor: pointer;
}

.user-chat__pin-button_darkTheme {
    color: rgba(255, 255, 255, .8);
}

.user-chat__pin-button_lightTheme {
    color: #000;
}

.user-chat__send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
}

.user-chat__send-button:disabled {
    opacity: .5;
}

.user-chat__send-button:disabled:hover {
    cursor: not-allowed;
}

.user-chat__send-button:hover {
    cursor: pointer;
}

.user-chat__input {
    width: calc(100% - 60px);
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    padding: 8px 15px;
    resize: none;
    line-height: 1.5;
    background-color: transparent;
}

.user-chat__input::placeholder {
    line-height: 1.5;
    font-size: 16px;
}

.user-chat__input:focus {
    line-height: 1.5;
    font-size: 16px;
}

.user-chat__input_darkTheme {
    color: var(--font-color-dark);
}

.user-chat__input_darkTheme::placeholder {
    color: rgba(255, 255, 255, .5);
}

.user-chat__input_lightTheme {
    background-color: var(--button-color-light);
    color: var(--font-color-light);
}

.user-chat__input_lightTheme::placeholder {
    color: var(--font-color-light);
}

.user-chat__input__block {
    display: flex;
    background-color: var(--list-item-color);
    align-items: center;
    width: 100%;
    padding: 5px 15px;
    border-radius: 12px;
    position: relative;
    min-height: 40px;
    box-shadow: var(--chat-shadow-color);
}

.user-chat__file-area {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2111;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.user-chat__file-area_darkTheme {
    background-color: #2E2E2E;
}

.user-chat__file-area_lightTheme {
    background-color: var(--primary-color-light);
}

.user-chat__pinned-file:first-of-type {
    border-radius: 10px 10px 0 0;
}

.user-chat__pinned-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    height: 40px;
    overflow: hidden;
}

.user-chat__pinned-file svg {
    width: 24px;
    color: var(--font-color);
}

.user-chat__pinned-file_darkTheme {
    background-color: #2E2E2E;
    color: var(--font-color-dark);
}

.user-chat__pinned-file_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.user-chat__pinned-file img {
    width: 15px;
    height: 15px;
    margin-left: auto;
}

.user-chat__pinned-file img:hover {
    cursor: pointer;
}

.user-message__div {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px 15px 5px 15px;
    width: 50%;
    margin-bottom: 10px;
    box-shadow: var(--chat-shadow-color);
}

.user-message__div:last-of-type {
    margin-bottom: 0;
}

.user-message__div_darkTheme {
    background-color: #484848;
    color: var(--font-color-dark);
}

.user-message__div_lightTheme {
    background-color: #fff;
    color: var(--font-color-light);
}

.user-message__info-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    color: var(--font-color-tr);
}

.user-message__info-block p {
    color: var(--font-color-tr);
}

.your-message {
    margin-left: auto;
}

.user-message__text-block {
    word-break: break-word;
    font-size: 16px;
    margin-bottom: 10px;
}

.message_file-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 0px;
    user-select: none;
    -webkit-user-drag: none;
}

.message_file-div svg {
    width: 20px;
    color: var(--font-color-tr);
}

.user-message__file-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.message_file-div:last-of-type {
    margin-bottom: 5px;
}

.message_file-div p {
    color: var(--font-color-tr);
}

.message_file-div:hover {
    cursor: pointer;
}

.message_file-div img {
    width: 25px;
    height: 25px;
}

.your-message #message__autor {
    visibility: hidden;
}

.user-chat__empty-chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.user-chat__empty-chat__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 192px;
    height: 58px;
    color: var(--font-color-tr);
    background-color: var(--chat-overlay-background-tr);
    border-radius: 10px;
    padding: 30px 20px;
}

.user-chat__send-button-div {
    height: 40px;
    padding: 5px;
    display: flex;
    border-radius: 12px;
    width: 50px;
    align-items: center;
    background-color: var(--list-item-color);
    justify-content: center;
    align-self: flex-end;
    box-shadow: var(--chat-shadow-color);
}

.user-chat__input-field {
    display: flex;
    flex-direction: column;
    background-color: var(--list-item-color);
    align-items: center;
    width: 100%;
    padding: 0px 15px;
    border-radius: 12px;
    position: relative;
    min-height: 40px;
}

@media screen and (max-width: 767px) {
    .user-chat__input-block__row {
        max-width: 100%;
    }
    .user-chat__input-block {
        height: auto;
    }
    .user-chat__input-row {
        width: 100%;
        border-radius: 0px;
    }

    .user-chat__input-block {
        width: 100%;
        padding: 0px;
    }

    .user-message__div {
        width: 90%;
        margin-bottom: 10px;
    }

    .user-chat__block {
        border-radius: 8px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        box-shadow: var(--chat-shadow-color);
    }

    .user-chat__block__overlay {
        border-radius: 0px;
    }

    .user-chat__input-field {
        max-width: calc(100% - 90px);
    }
}

@media screen and (max-width: 1279px) {
    .user-chat__input-row {
        border-radius: 0px;
        width: 100%;
    }

    .user-message__div {
        width: 80%;
    }

    .user-chat__input-block {
        padding: 0;
        width: 100%;
    }

}