.popup__body {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 80px);
    max-width: 1024px;
    min-height: 500px;
    max-height: 560px;
    border-radius: 10px;
    padding: 20px;
    z-index: 988;
}

.popup__body_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
}

.popup__body_darkTheme {
    background-color: #252525;
    color: var(--font-color-dark);
}

.popup__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
    max-height: calc(100% - 60px)
}

.service__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: transparent;
    font-size: 16px;
    justify-content: flex-start;
    border: none;
    user-select: none;
    -webkit-user-drag: none;
}

.service__item svg {
    width: 25px;
    height: 25px;
}

.service__item_lightTheme {
    color: var(--font-color-light);
}

.service__item_lightTheme:hover {
    background-color: rgba(0, 0, 0, .05);
    fill: #0082C8;
    color: #0082C8;
}

.service__item_darkTheme {
    color: #ffffffcc;
}

.service__item_darkTheme:hover {
    background-color: rgba(255, 255, 255, 0.08);
    color: #fff;
    fill: #fff;
}

.service__item:hover {
    cursor: pointer;
}

.service-component__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: none;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: transparent;
    font-size: 16px;
    text-align: left;
    justify-content: flex-start;
    user-select: none;
    -webkit-user-drag: none;
}

.service-component__item:hover {
    cursor: pointer;
}

.popup__back-button {
    position: relative;
    left: 0px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
}

.popup__close-button {
    width: 30px;
    position: relative;
    right: 0px;
    height: 30px;
    background-color: transparent;
    border: none;
    align-self: flex-end;
}

.popup__button-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
}

.popup__form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    gap: 20px;
}

.popup__form__button-block {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.popup__textarea {
    border-radius: 10px;
    padding: 10px;
    resize: none;
    width: calc(100% - 20px);
    font-size: 16px;
    height: 100%;
    border-color: var(--border-color);
    color: var(--font-color);
    transition: .3s ease-in-out;
}

.popup__textarea:hover {
    border-color: var(--border-color-hover);
}

.popup__textarea:focus {
    border-color: var(--border-color-focus);
}

.popup__textarea:focus::placeholder {
    color: var(--font-color);
}

.popup__textarea_darkTheme {
    background-color: var(--block-color-dark);
}

.popup__textarea_lightTheme {
    background-color: var(--primary-color-light);
}

.popup__textarea::placeholder {
    color: var(--font-color-tr);
}

.popup__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.popup__form__submit-button {
    border-radius: 10px;
    font-weight: bold;
    width: 150px;
    height: 45px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--button-color);
    color: var(--button-text-color);
}

.result__div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.result__div svg {
    color: var(--font-color);
}

.popup__form__text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}


.popup__form__select-block {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
}

.popup__form__cpo-button-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: center;
    align-items: center;
}

.popup__form__button-cpo {
    height: 45px;
    width: 165px;
    border: 2px solid var(--header-menu-icon-color);
    border-radius: 10px;
    background: transparent;
    color: var(--header-menu-icon-color);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.popup__form__button-cpo svg {
    color: var(--font-color);
}

.popup__form__cpo-button-block a {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    user-select: none;
    -webkit-user-drag: none;
    color: var(--font-color);
}

.popup__form__cpo-button-block a:hover {
    cursor: pointer;
}

.popup__form__cpo-input-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.popup__form__cpo-input-block label {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    user-select: none;
    -webkit-user-drag: none;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.popup__form__cpo-input-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.popup__form__cpo-input-row input {
    width: 50%;
}

.popup__form__input {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--input-background-color);
    padding: 10px;
    font-size: 16px;
    color: var(--font-color);
    transition: .3s ease-in-out;
    width: calc(100% - 20px);
}

.popup__form__input::placeholder {
    color: var(--font-color-tr);
}

.popup__form__input:focus {
    outline: none;
    border-color: var(--border-color-focus);
}

.popup__form__input:hover {
    border-color: var(--border-color-hover);
}

.popup__form__input:focus:hover {
    border-color: var(--border-color-focus);
}

.popup__form__input:disabled:hover {
    border-color: var(--border-color);
}

.user-incident-list__error-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 55px);
    align-items: center;
    justify-content: center;
    color: var(--font-color);
}

.popup__form__bank-trust-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.popup__form__bank-trust-block a {
    color: var(--font-color);
    font-weight: bold;
}

.popup__form__bank-trust-block label {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    user-select: none;
    -webkit-user-drag: none;
    align-items: center;
    justify-content: center;
}

.popup__form__vpn-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    gap: 10px;
}

.popup__form__vpn-block label {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    user-select: none;
    -webkit-user-drag: none;
    align-items: center;
    justify-content: center;
}

.popup__form__vpn-block a {
    color: var(--font-color);
    font-weight: bold;
    text-decoration: underline;
}

.popup__form__folder-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    position: relative;
    align-items: center;
}

.popup__form__rows-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
    position: relative;
    flex-grow: 1;
}

.popup__form__folder-svg {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -45px;
    z-index: 999;
}

.popup__form__folder-svg:hover {
    cursor: pointer;
}

.add-row-button {
    width: 30px;
    position: relative;
    top: 10px;
}

.add-row-button:hover {
    cursor: pointer;
}

.popup__form__placeholder-div {
    width: 100%;
    position: relative;
}

.popup__form__placeholder-div p {
    font-size: 14px;
    color: var(--font-color-tr);
}

.popup__form__redirect-p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.popup__form__redirect-p p {
    padding: 10px;
    width: calc(100% - 20px);
    text-align: center;
    max-width: 550px;
}

.popup__form__calendar-div {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--input-background-color);
    padding: 10px;
    font-size: 16px;
    color: var(--font-color);
    transition: .3s ease-in-out;
    width: calc(100% - 20px);
    height: 23px;
}

.popup__form__1c-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}

@media screen and (max-width: 1024px) {
    .popup__form {
        gap: 10px;
    }
}

@media screen and (max-width: 767px) {
    .popup__grid {
        gap: 5px;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        height: calc(100% - 40px);
        width: 100%;
    }

    .popup__textarea {
        height: 100%;
    }

    .service__item {
        padding: 20px 15px;
        width: 100%;
    }

    .service__item:hover {
        background-color: transparent;
        color: var(--font-color);
    }

    .service-component__item:hover {
        background-color: transparent;
        color: var(--font-color);
    }

    .service__item svg {
        width: 24px;
        height: 24px;
    }

    .popup__body {
        width: 100%;
        padding: 0px;
        height: 100%;
        max-height: 100%;
        border-radius: 0px;
        padding-bottom: 15px;
    }

    .popup__button-block {
        padding: 15px 15px 0px 15px;
    }

    .popup__form {
        width: 100%;
        gap: 30px;
        padding: 15px;
    }

    .popup__form__submit-button {
        height: 45px;
    }

    .popup__form__select-block {
        flex-direction: column;
        gap: 15px;
    }

    .popup__form__vpn-block {
        gap: 30px;
    }
}

@media screen and (max-height: 685px) {
    .popup__form {
        gap: 15px;
    }
}