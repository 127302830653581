.calendar__layout {
    position: absolute;
}

.calendar__layout {
    padding: 8px;
    border-radius: 10px;
    background-color: var(--input-background-color);
    font-family: inherit;
    width: 275px;
    position: absolute;
    left: -35px;
    border: 1px solid var(--border-color);
    margin-top: 10px;
}

.react-calendar__month-view__days__day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    padding: 6px 0px;
    background-color: transparent;
    color: var(--font-color);
    transition: .3s ease-in-out;
}

.react-calendar__month-view__days__day:hover {
    background-color: var(--block-color-active);
}

.react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

abbr[title] {
    text-decoration: none !important;
}

.react-calendar__navigation {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.react-calendar__tile--active {
    background-color: var(--calendar-day-active-color);
    color: var(--button-text-color);
}

.react-calendar__navigation__arrow {
    background-color: transparent;
    border: none;
    color: var(--font-color);
    font-size: 20px;
    padding: 0px 10px;
}

.react-calendar__navigation__label {
    margin: 0;
    background-color: transparent;
    color: var(--font-color);
    border: none;
    font-size: 16px;
}

.react-calendar__navigation__label::first-letter {
    text-transform: uppercase;
}

.react-calendar__navigation {
    padding-bottom: 10px;
}