.auth-form {
    display: flex;
    flex-direction: row;
    border-radius: 24px;
    height: 100%;
    max-width: 900px;
    max-height: 500px;
    font-size: 15px;
    width: calc(100% - 40px);
}



.auth-form__form-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    width: calc(50% - 80px);
    padding: 40px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 24px 0px 0px 24px;
}

.auth-form__form-div_darkTheme {
    color: var(--font-color-dark);
}

.auth-form__form-div_lightTheme {
    color: var(--font-color-light);
    background-color: var(--primary-color-light);
}


.auth-form__overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 50%;
    max-width: 50%;
    border-radius: 0px 24px 24px 0px;
}

.auth-form__overlay_darkTheme {
    background-color: var(--primary-color-dark);
}

.auth-form__overlay_lightTheme {
    background-color: rgba(255, 255, 255, .4);
}

.auth-form__title {
    font-size: 24px;
    font-weight: bold;
}

.auth-form__input {
    padding: 10px;
    height: 20px;
    border-radius: 8px;
    outline: none;
    border: none;
    transition: .2s ease-in-out;
    font-family: 'Circe';
    font-size: 15px;
}

.auth-form__button:disabled:hover {
    box-shadow: none;
}

.auth-form_lightTheme {
    box-shadow: 0px 0px 30px 0px rgba(0, 130, 200, 0.25);
}

.auth-form__input_lightTheme {
    background-color: var(--primary-color-light);
    color: var(--font-color-light);
    font-weight: bold;
}

.auth-form__input_lightTheme::placeholder {
    color: var(--font-color-light);
}

.auth-form__input_lightTheme:focus {
    box-shadow: var(--secondary-color-light) 0px 0px 0px 1px inset;
}

.auth-form_darkTheme {
    background-color: #2E2E2E;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.auth-form__input_darkTheme {
    background-color: var(--secondary-color-dark);
    color: var(--font-color-dark);
}

.auth-form__input_darkTheme::placeholder {
    color: var(--font-color-dark);
}

.auth-form__input_darkTheme:focus {
    box-shadow: #fff9 0px 0px 0px 1px inset;
}

.auth-form__submit-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.auth-form__button {
    border: none;
    border-radius: 8px;
    height: 45px;
    min-height: 45px;
    font-family: 'Circe';
    transition: .3s ease-in-out;
    font-size: 15px;
    font-weight: bold;
    transition: .3s ease-in-out;
}

.auth-form__button:hover {
    cursor: pointer;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}

.auth-form__button_darkTheme {
    background-color: var(--button-color-dark);
    color: rgba(255, 255, 255, .8);
}

.auth-form__qr-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 260px;
    max-height: 350px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 12px;
    text-align: center;
    z-index: 200;
}

.auth-form__qr-block_darkTheme {
    background-color: #2E2E2E;
    color: var(--font-color-dark);
    box-shadow: 0px 0px 21.3px 0px rgba(0, 0, 0, 0.20);
}

.auth-form__qr-block_lightTheme {
    background-color: var(--primary-color-light);
    box-shadow: 0px 0px 21.3px 0px rgba(0, 130, 200, 0.20);
    color: var(--font-color-light);
}

.auth-form__qr-img {
    width: 100%;
    height: 100%;
    max-height: 150px;
    max-width: 150px;
}

.auth-form__background-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 0px 24px 24px 0px;
    object-fit: cover;
    height: 100%;
    z-index: 1;

}

.auth-form__secondary-button {
    width: 100%;
    height: 45px;
    border-radius: 52px;
    border: none;
    background-color: transparent;
    color: #0082C8;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #0082C8;
    transition: .3s ease-in-out;
}

.auth-form__secondary-button:hover {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
}

.auth-form__button {
    width: 100%;
    height: 45px;
    border-radius: 52px;
    border: none;
    background-color: #0082C8;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: bold;
}

.input__div {
    text-align: left;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 85px;
    min-height: 85px;
    max-height: 85px;
}

.auth-form__separator {
    width: 90%;
    height: 1px;
}

.auth-form__separator_darkTheme {
    background-color: rgba(255, 255, 255, .2)
}


.auth-form__separator_lightTheme {
    background-color: #00000033;
}

.auth-form__hide-password {
    position: absolute;
    right: 15px;
    top: 36px;
    transition: .3s ease-in-out;
    opacity: .7;
}

.auth-form__hide-password:hover {
    cursor: pointer;
    opacity: 1;
}

.auth-form__subtitle {
    font-size: 14px;
    line-height: 1.2;
    opacity: .6;
}

.auth-form__form-div_darkTheme .auth-form__button {
    background-color: #4B4B4B;
}

.auth-form__form-div_darkTheme .auth-form__secondary-button {
    border: 1px solid #4B4B4B;
    color: rgba(255, 255, 255, .8);
}

.cannot-login__popup__body {
    display: flex;
    position: relative;
    max-width: 308px;
    border-radius: 24px;
    width: calc(100% - 40px);
    padding: 40px;
    flex-direction: column;
    color: var(--font-color);
    background-color: var(--list-item-color);
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.cannot-login__popup__body h2 {
    text-align: center;
    line-height: 120%;
    font-size: 24px;
}

.cannot-login__close-button {
    position: absolute;
    border: none;
    background-color: transparent;
    right: -30px;
    top: 0px;
    width: 30px;
    height: 30px;
}

.cannot-login__popup__ul {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: list;
}

.cannot-login__popup__ul li {
    display: flex;
    align-items: flex-start; /* Выравнивание номера по верхней части текста */
    margin-bottom: 15px;
    position: relative; /* Создание контекста для позиционирования номера */
}

.cannot-login__popup__number {
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
    color: var(--deny-button-text-color); /* Отступ между номером и текстом */
    position: absolute; /* Абсолютное позиционирование номера */
    left: 0; /* Расположение относительно левой границы li */
    top: 0; /* Расположение относительно верхней границы li */
}

.cannot-login__popup__ul li {
    padding-left: 25px; /* Увеличение отступа для текста, чтобы оставить место для маркера */
}


.cannot-login__popup__button {
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: none;
    width: 100%;
    height: 45px;
    font-size: 16px;
    border-radius: 52px;
    font-weight: bold;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .auth-form {
        width: 100%;
        border-radius: 0px;
        max-height: 100%;
        justify-content: center;
    }

    .auth-form__form-div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        width: 100%;
        max-width: 332px;
        padding: 20px;
        align-items: center;
        justify-content: center;
    }

    .cannot-login__popup__body {
        padding: 25px;
    }

    .cannot-login__close-button {
        top: -35gipx;
        right: 0px;
    }
}