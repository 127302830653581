.user-incident-detail__file {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: var(--font-color);
    justify-content: space-between;
    padding: 5px 0px;
}

.user-incident-detail__file svg {
    width: 25px;
    height: 25px;
}

.user-incident-detail__file-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: auto;
    overflow-x: hidden;
}

.file-list-item__download-button:hover {
    cursor: pointer;
}